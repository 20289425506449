<template>
    <div class="content-page">
        <div class="content-nav">
            <el-breadcrumb class="breadcrumb" separator="/">
                <el-breadcrumb-item :to="{ name: 'xxjgs_Questions' }">小小建构师题库</el-breadcrumb-item>
                <el-breadcrumb-item>{{infoForm._id ? '编辑题目' : '添加题目'}}</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="operation-nav">
                <el-button type="primary" @click="onSubmitInfo">确定保存</el-button>
                <el-button @click="goBackPage" icon="arrow-left">返回列表</el-button>
            </div>
        </div>
        <div class="content-main">
            <div class="form-table-box">
                <el-form ref="infoForm" :rules="infoRules" :model="infoForm" label-width="120px">
                    
                    <el-form-item label="题目编号" prop="question_no">
                        <el-input type="number" v-model="infoForm.question_no"></el-input>
                        <div v-if="!!!infoForm._id" class="el-upload__tip">存在题库最大编号为：{{maxQuestionNo}}</div>
                    </el-form-item>

                    <el-form-item label="使用的方块" prop="selectBlockUrl" v-if="!!selectBlockUrl"
                            class="image-uploader-diy new-height">
                        <img v-if="selectBlockUrl" :src="selectBlockUrl" class="image-show">
                        <el-button class="dele-list-pic" type="primary" @click="delePicList">
                            <i class="fa fa-trash-o"></i>
                        </el-button>
                    </el-form-item>
                    
                    <el-form-item label="使用的方块" prop="selectBlockUrl" v-if="!selectBlockUrl">
                        <el-upload
                                name="file"
                                class="upload-demo"
                                :action="selectBlockUploadZone"
                                :on-success="handleUploadSelectBlockSuccess"
                                :on-remove="selectBlockUrlRemove"
                                list-type="picture-card"
                                :data="selectBlockUrl"
                        >
                            <el-button size="small" type="primary">点击上传</el-button>
                            <div slot="tip" class="el-upload__tip">图片长宽：500*500 | 只能上传jpg/png文件，且不超过500kb</div>
                        </el-upload>
                    </el-form-item>

                    <el-form-item label="添加条件">
                        <div>
                         
                            <el-upload
                                class="upload-demo"
                                :action="conditionUploadZone"
                                name="conditions"
                                :multiple=true
                                :on-success="handleConditinoUploadSuccess"
                                :show-file-list="false"
                                list-type="picture-card"
                                >
                                <el-button size="small" type="primary">点击上传</el-button>
                                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
                            </el-upload>

                            <div v-loading="loadingapp" class="img-wrapper divBox" v-for="(item,index) in conditionUrls" >
                                <img :src="item.url" v-if="item.url" width="148" height="148" class="imgSty">
                                <i class="el-icon-delete delIcon" @click="deleConditionImg(item.url,index)"></i>
                                <!--<img class="img-big" :src="imgUrl" v-else width="362" height="272">-->
                            </div>
                        </div>
                    </el-form-item>

                    <el-form-item label="添加答案">
                        <div>
                            <el-upload
                                class="upload-demo"
                                :action="answerUploadZone"
                                name="answers"
                                :multiple=true
                                :on-success="handleAnswerUploadSuccess"
                                :show-file-list="false"
                                list-type="picture-card"
                                >
                                <el-button size="small" type="primary">点击上传</el-button>
                                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
                            </el-upload>

                            <div v-loading="loadingapp" class="img-wrapper divBox" v-for="(item,index) in answerUrls" >
                                <img :src="item.url" v-if="item.url" width="148" height="148" class="imgSty">
                                <i class="el-icon-delete delIcon" @click="deleAnswerImg(item.url,index)"></i>
                                <!--<img class="img-big" :src="imgUrl" v-else width="362" height="272">-->
                            </div>
                        </div>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="onSubmitInfo">确定保存</el-button>
                        <el-button @click="goBackPage">返回列表</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
    import api from '@/config/api';
    import $ from 'jquery'
    export default {
        data() {
            return {
                root: '',
                conditionUploadZone:'',
                answerUploadZone:'',
                selectBlockUploadZone:'',
                answerUrls:[],
                conditionUrls:[],
                selectBlockUrl:null,
                questionId:"",
                infoForm:{
                        question_no:null,
                        conditions:[],
                        answers:[],
                        selectblock:null
                },
                infoRules: {
                    
                    question_no: [
                        {required: true, message: '请输入题目编号!', trigger: 'blur'},
                    ],
                    selectblock: [
                        {required: true, message: '请选择方块使用图片!', trigger: 'blur'},
                    ],
                    answers: [
                        {required: true, message: '请选择答案图片!', trigger: 'blur'},
                    ],
                    conditions: [
                        {required: true, message: '请选择条件图片', trigger: 'blur'},
                    ]
                },
                maxQuestionNo:0
            }
        },
        methods: {

            goBackPage() {
                this.$router.go(-1);
            },
            onSubmitInfo() {
                this.$refs['infoForm'].validate((valid) => {
                    if (valid) {
                        
                        var binfo ={
                            question_no:this.infoForm.question_no,
                            conditions:this.infoForm.conditions,
                            answers:this.infoForm.answers,
                            selectblock:this.infoForm.selectblock
                        }

                        console.log(binfo);

                        if(!!binfo.conditions&&binfo.conditions.length>0&&!!binfo.answers&&binfo.answers.length>0&& binfo.selectblock)
                        {
                           
                        }
                        else
                        {
                            this.$message({
                                type: 'error',
                                message: '图片信息不完整'
                            })
                            return;
                        }

                        console.log(binfo);

                        if(!!this.infoForm._id)
                        {
                            this.axios.post('dayquestion/udpateXXJGSQuesioion',
                            {
                                questionInfo: binfo,
                                id:this.infoForm._id
                            }).then((response) => {
                                console.log("保存结果出来了！！！");
                                console.log(response);
                            if (response.data.code> 0) {
                                this.$message({
                                    type: 'success',
                                    message: '更新成功'
                                });
                                this.$router.go(-1);
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: '更新失败'
                                })
                            }
                        })
                        }
                        else
                        {
                            this.axios.post('dayquestion/addXXJGSQuestion',
                            {
                                questionInfo: binfo
                            }).then((response) => {
                                console.log("保存结果出来了！！！");
                                console.log(response);
                            if (response.data.code> 0) {
                                this.$message({
                                    type: 'success',
                                    message: '保存成功'
                                });
                                this.$router.go(-1);
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: '保存失败'
                                })
                            }
                        })
                        }
                        
                    } else {
                        return false;
                    }
                });
            },
         
            hasErrorAct(err) {
                console.log(err);
            },
            delePicList() {
                // 不删除服务器上的图片，上传新的自动替换旧的
                this.selectBlockUrl = null;
                this.infoForm.selectblock = null;
            },
            selectBlockUrlRemove()
            {
                this.selectBlockUrl = null;
                this.infoForm.selectblock = null;
            },
            handleUploadSelectBlockSuccess(res) {
                if(res.code<0)
                {
                    return;
                }
                let url = res.url;
                this.selectBlockUrl = this.fileRoot + url;
                this.infoForm.selectblock = url;
            },
            handleConditinoUploadSuccess(res) {
                if(res.code<0)
                {
                    return;
                }
                let url = res.url;
                let conditinoInfo = {
                    url:url,
                    name:url
                }
                this.infoForm.conditions.push(conditinoInfo);
                let urlData = this.fileRoot + url;
                let info = {
                    url: urlData,
                    name:urlData
                }
                this.conditionUrls.push(info);
                console.log(this.conditionUrls);
            },
            deleConditionImg(data,index){
                this.conditionUrls.splice(index,1);
                this.infoForm.conditions.splice(index,1);
            },
            handleAnswerUploadSuccess(res) {
                if(res.code<0)
                {
                    return;
                }
                let url = res.url;
                let answerInfo = {
                    url:url,
                    name:url
                }
                this.infoForm.answers.push(answerInfo);
                let urlData = this.fileRoot + url;
                let info = {
                    url: urlData,
                    name:urlData
                }
                this.answerUrls.push(info);
            },
            deleAnswerImg(data,index){
                this.answerUrls.splice(index,1);
                this.infoForm.answers.splice(index,1);
            },
            recoverData()
            {
                var conditions = this.infoForm.conditions;
                for(var i = 0;i!= conditions.length;i++)
                {
                    let urlData = this.fileRoot + conditions[i].url;
                    let info = {
                        url: urlData,
                        name:urlData
                    }
                    this.conditionUrls.push(info);
                }

                var answers = this.infoForm.answers;
                for(var i = 0;i!= answers.length;i++)
                {
                    let urlData = this.fileRoot + answers[i].url;
                    let info = {
                        url: urlData,
                        name:urlData
                    }
                    this.answerUrls.push(info);
                }

                this.selectBlockUrl = this.fileRoot + this.infoForm.selectblock;

            },
        },
        components: {
        },
        computed: {
        },
        mounted() {
            
            this.root = api.rootUrl;
            this.fileRoot = api.rootFileUrl;
            this.answerUploadZone = api.rootUrl+"dayquestion/uploadXXJGSAnswers";
            this.conditionUploadZone = api.rootUrl+"dayquestion/uploadXXJGSConditions";
            this.selectBlockUploadZone = api.rootUrl+"dayquestion/uploadXXJGSSelectBlock";

            var maxNo = this.$route.query.maxNo;
            this.maxQuestionNo = maxNo;
            if(!!this.$route.query.questionInfo)
            {
                this.infoForm = this.$route.query.questionInfo ;
                this.recoverData();
            }
        },
    }

</script>

<style scoped>
    /* .edit_container{ */
    /*.avatar-uploader .el-upload {*/
    /*display: none;*/
    /*}*/

    .imgSty{
		margin-left: 3px;
        min-width: 105px;
        height: 105px;
        display: block;
	}

    .divBox{
		/*flex-direction: row;*/
		float: left;
		position: relative;
	}

	.newtag{
		position: relative;
		cursor: text;
		overflow: visible;
		display: inline-block;
		padding: 0;	
	}

    .input-wrap .el-input {
        width: 200px;
        float: left;
        margin: 0 20px 20px 0;
    }
    .image-uploader-diy {
        /*height: 200px;*/
        position: relative;
    }

    /*.dele-list-pic {*/
        /*position: absolute;*/
        /*left: 380px;*/
        /*top: 0px;*/
    /*}*/

    .image-uploader-diy .el-upload {
        border: 1px solid #d9d9d9;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .image-uploader-diy .el-upload:hover {
        border-color: #20a0ff;
    }

    .image-uploader-diy .image-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 200px;
        height: 200px;
        line-height: 200px;
        text-align: center;
    }

    .image-uploader-diy .image-show {
        min-width: 105px;
        height: 105px;
        display: block;
    }

    .image-uploader-diy .new-image-uploader {
        font-size: 28px;
        color: #8c939d;
        width: 165px;
        height: 105px;
        line-height: 105px;
        text-align: center;
    }

    .image-uploader-diy .new-image-uploader .image-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 165px;
        height: 105px;
        line-height: 105px;
        text-align: center;
    }

    .image-uploader-diy .new-image-uploader .image-show {
        width: 165px;
        height: 105px;
        display: block;
    }

    .input-wrap .el-input input {
        background-color: #fff !important;
        color: #233445 !important;
    }

    .specFormDialig .el-input {
        width: 150px;
        margin-right: 10px;
    }

    .el-select-class {
        width: 200px;
        margin-right: 20px;
    }


    .float-right {
        float: right;
    }

    .sepc-form .el-input {
        width: 200px;
        margin-right: 20px;
    }

    .marginTop20 {
        margin-top: 20px;
    }

    .hidden {
        display: none;
    }
    .animal_select
    {

    }
    .animal_select  .el-input__inner{
        height: 30px;
    }
    /* 下面设置右侧按钮居中 */
    .animal_select  .el-input__suffix {
        top: 5px;
    }
    .animal_select   .el-input__icon {
        line-height: inherit;
    }
    .animal_select  .el-input__suffix-inner {
        display: inline-block;
    }
</style>
